const cryptousdt = [{
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'BTC',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'ETH',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'BNB',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'XRP',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'EOS',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'TRX',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'ETC',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'VET',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'USDC',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'BTT',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'MATIC',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'DOGE',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'WIN',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'DENT',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'YFI',
        status: 'Buy'
    },
    {
        image: 'http://192.168.1.74/server/Laravel/Exchange-Branch/exchange/public/currency_images/1630994257.svg',
        currency: 'CAKE',
        status: 'Buy'
    },

]

export { cryptousdt };